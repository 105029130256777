<template>
  <ion-content class="ion-padding caution">
    <table>
      <tr>
        <td width="25%" valign="top">
          <div style="padding: 10px">
            <img src="../../../public/assets/icon/exclamacion.svg" />
          </div>
        </td>
        <td>
          <div style="padding: 0px 0 0px 10px">
            <span class="cautionTitle"> Datos incompletos </span>
          </div>

          <div style="padding: 0 10px">
            <span class="cautionContent">
              Olvidaste cargar un dato del formulario.
            </span>
          </div>
        </td>
        <td width="10%" valign="top">
          <div style="padding-top: 0px; cursor: pointer">
            <img
              src="../../../public/assets/icon/closeModal.svg"
              v-on:click="cancel"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td width="25%" valign="top"></td>
        <td colspan="2">
          <table>
            <tr>
              <td>
                <div style="padding: 15px 10px 0px 10px">
                  <span
                    class="cautionContent"
                    style="letter-spacing: 1.5px"
                    v-on:click="cancel"
                  >
                    EDITAR
                  </span>
                </div>
              </td>
              <td>
                <div style="padding: 15px 10px 0px 10px">
                  <span
                    class="cautionContent"
                    style="letter-spacing: 1.5px"
                    v-on:click="confirm"
                  >
                    ENVIAR IGUAL
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </ion-content>

</template>

<script>
import { IonContent, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import router from "../../router/index";

export default defineComponent({
  name: "ModalDatosIncompletos",
  data() {
    return {};
  },
  components: { IonContent },
  methods: {
    cancel() {
      const modal = modalController;
      const data = false;
      return modal.dismiss(data);
    },
    confirm () {
      const modal = modalController;
      const data = true;
      return modal.dismiss(data);
    }
  },
});
</script>

<style scoped>
.caution {
  --background: #d46827;
  text-align: left;
  height: 50%!important;
}

.cautionTitle {
  color: white;
  font-family: "Avenir";
  font-size: 20px;
  letter-spacing: 1px;
}

.cautionContent {
  color: white;
  font-family: "Avenir";
  font-size: 12px;
  letter-spacing: 0.75px;
  cursor: pointer;
}
</style>

