<template>
  <ion-content class="ion-padding alert">
    <table>
      <tr>
        <td>
          <div style="padding: 10px 0 5px 10px">
            <span class="alertTitle"> Comidas cargada </span>
          </div>

          <div style="padding: 0 10px">
            <span class="alertContent">
              ¿Confirmar el envío?
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <table>
            <tr>
              <td>
                <div style="padding: 35px 10px 0px 10px">
                  <span
                    class="alertAction"
                    style="letter-spacing: 1.5px"
                    v-on:click="cancel"
                  >
                    CANCELAR
                  </span>
                </div>
              </td>
              <td>
                <div style="padding: 35px 10px 0px 10px">
                  <span
                    class="alertAction"
                    style="letter-spacing: 1.5px"
                    v-on:click="confirm"
                  >
                    ENVIAR
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td width="10%"></td>
      </tr>
    </table>
  </ion-content>

</template>

<script>
import { IonContent, modalController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalGuardarComidas",
  data() {
    return {};
  },
  components: { IonContent },
  methods: {
    cancel() {
      const modal = modalController;
      const data = false;
      return modal.dismiss(data);
    },
    confirm () {
      const modal = modalController;
      const data = true;
      return modal.dismiss(data);
    }
  },
});
</script>

<style scoped>
.alert {
  --background: #ffffff;
  text-align: left;
}

.alertTitle {
  color: #4a4a4a;
  font-family: "Avenir";
  font-size: 20px;
  letter-spacing: 1px;
}

.alertContent {
  color: #4a4a4a;
  font-family: "Avenir";
  font-size: 14px;
  letter-spacing: 0.75px;
}

.alertAction {
  color: #E33613;
  font-family: "Avenir";
  font-size: 14px;
  letter-spacing: 0.75px;
}
</style>

